.payment-success-container {
  background-color: #fff;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  text-align: center;
  padding: 40px;
  width: 100%;
  max-width: 450px;
  margin: auto;
}

.ph1 {
  font-size: 28px;
  color: #4caf50;
  margin-bottom: 10px;
}

.checkmark {
  font-size: 50px;
  color: #4caf50;
}

.paymentp {
  font-size: 16px;
  color: #555;
  margin: 10px 0;
}

.order-details {
  background-color: #f0f0f0;
  padding: 15px;
  border-radius: 8px;
  margin: 20px 0;
}

.order-details h2 {
  margin: 0;
  font-size: 20px;
  color: #333;
}
