.slick_item {
  height: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  /* padding: 0 2%; */
  box-sizing: border-box;
}

.slider_image {
  max-height: 300px;
  width: auto;
  object-fit: cover;
}

.content {
  margin-top: 10px;
}

.slick .slick-list {
  overflow: hidden;
}

.slick .slick-track {
  display: flex;
  align-items: center;
}

.slick .slick-slide {
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 767px) {
  .slick_item {
    height: 150px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    /* padding: 0 2%; */
    box-sizing: border-box;
  }
  .content {
    margin-top: 5px;
  }
}
::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background-color: #fe5900;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #fe5900;
}

/* Firefox scrollbar */
* {
  scrollbar-width: thin;
  scrollbar-color: #fe5900 #f1f1f1;
}
