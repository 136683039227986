/* SponsorsSection.css */
.sponsors-container {
    /* background-color: #716f6f55; */
    text-align: center;
    padding: 40px 20px;
    margin: 10rem 0rem 2.5rem 0rem
  }
  
  .sponsors-heading {
    font-size: 2.75rem;
    font-weight: 600;
    color: black;
  }
  
  .sponsors-subheading {
    font-size: 1rem;
    margin: 0 0 30px;
    color: #555;
  }
  
  .sponsors-banner {
    display: flex; 
    justify-content: center; 
    align-items: center; 
    margin: 20px 0; 
  }
  
  .sponsors-banner img {
    max-width: 100%; 
    height: auto; 
    object-fit: cover; 
  }
  .sponsor-banner-img {
    transition: transform 0.3s ease, filter 0.3s ease; 
  }
  
  .sponsor-banner-img:hover {
    transform: scale(1.1); 
    filter: brightness(1.2); 
    cursor: pointer; 
  }
  /* Responsive styling */
  @media (max-width: 768px) {
    .sponsor-logo {
      max-width: 120px;
    }
  }
  
  @media (max-width: 480px) {
    .sponsors-heading {
      font-size: 1.5rem;
    }
  
    .sponsors-subheading {
      font-size: 0.9rem;
    }

  }
  