.tab_content {
  width: 100%;
}

.schedule-container {
  max-height: 650px; /* Adjust the height as needed */
  overflow-y: auto;
  /* border: 1px solid #ccc; Optional: add border for better visibility */
  margin-top: -1px; /* Optional: add margin to align with the table header */
}

.schedule-container table {
  width: 100%;
  border-collapse: collapse;
}

/* Custom scrollbar styles */
.orange-scrollbar::-webkit-scrollbar {
  width: 4px; /* Adjust width as needed */
}

.orange-scrollbar::-webkit-scrollbar-thumb {
  background-color: #fe5900;
}

.orange-scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1; /* Optional: adjust track background color */
}

/* For Firefox */
.orange-scrollbar {
  scrollbar-width: thin; /* Adjust width as needed */
  scrollbar-color: #fe5900 #f1f1f1; /* thumb color, track color */
}

.input_group {
  display: inline-block;
  /* margin-right: 10px; */
}
.selection {
  background-color: #fe5900;
  color: white;
  border: none;
  padding: 10px;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
}
.selection:focus {
  background-color: white;
  color: #fe5900;
  outline: none;
}
