.menu-item-has-children {
  position: relative;
}

.sub-menu {
  display: none; /* Hidden by default */
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #fe5900;
  padding: 0;
  margin: 0;
  list-style: none;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 999;
  border-radius: 10px;
}

.sub-menu li {
  padding: 10px;
  white-space: nowrap;
}

.sub-menu li a {
  color: #000;
  text-decoration: none;
  display: block;
}

.sub-menu li a:hover {
  background-color: #fe5900;
}

.menu-item-has-children:hover .sub-menu {
  display: block; /* Show the dropdown on hover */
}
