/* Rememberlegends.css */

.image-grid-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 50px;
  box-sizing: border-box;
}
.players_section {
  padding-left: 55px;
  padding-right: 55px;
}
.image-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 10px;
  max-width: 90%;
  margin: auto;
}

.grid-item {
  position: relative;
  overflow: hidden;
}

.grid-item img {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
}

.overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  text-align: center;
  padding: 10px;
}

.text {
  font-size: 16px;
  font-weight: bold;
}

/* Custom grid item spans to achieve a varied layout */
.grid-item-1 {
  grid-column: span 2;
  grid-row: span 2;
}

.grid-item-2 {
  grid-column: span 1;
  grid-row: span 1;
}

.grid-item-3 {
  grid-column: span 2;
  grid-row: span 1;
}

.grid-item-4 {
  grid-column: span 1;
  grid-row: span 2;
}

.grid-item-5,
.grid-item-6,
.grid-item-7,
.grid-item-8 {
  grid-column: span 1;
  grid-row: span 1;
}

.grid-item-5 {
  grid-column: span 3;
  grid-row: span 1;
}

.grid-item-6 {
  grid-column: span 2;
  grid-row: span 1;
}

.grid-item-7 {
  grid-column: span 1;
  grid-row: span 1;
}

.grid-item-8 {
  grid-column: span 1;
  grid-row: span 2;
}
