.players-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  text-align: center;
}

.players-table th,
.players-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
}

.players-table th {
  background-color: #fe5900;
  color: white;
  position: sticky;
  top: 0;
}

.players-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.players-table tr:hover {
  background-color: #f1f1f1;
}

.players-table .loading {
  text-align: center;
  padding: 20px;
}
