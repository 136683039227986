.slick-dots li button:before {
  color: gray; /* Color of the inactive dots */
  opacity: 1; /* Ensure the dots are fully visible */
  font-size: 10px;
  position: absolute;
  margin-bottom: -80%; /* Adjust as needed */
  left: 20px; /* Adjust as needed */
  z-index: 10;
}

.slick-dots li.slick-active button:before {
  font-size: 16px;
  color: #ff6600; /* Color of the active dot */
  opacity: 1; /* Ensure the active dot is fully visible */
  position: absolute;
  margin-bottom: -80%; /* Adjust as needed */
  left: 20px; /* Adjust as needed */
  z-index: 10;
}

.team_section {
  position: relative;
}

.team_slick .slick_item {
  position: relative;
  height: 717.25px !important;
  width: 100% !important;
  text-align: left;
}

.team_slick .slick_item img {
  width: 100%;
  height: 717.25px;
}

.team_slick .slick_item .content {
  position: absolute;
  bottom: 20px;
  left: 20px;
  padding-left: 100px;
  z-index: 10; /* Make sure it appears on top */
  color: #fff !important; /* Ensure text is visible on image */
  font-size: 2.75rem;
}
.team_slick .slick_item .content h3 {
  color: #fff !important;
}
