/* Pagination.css */
.pagination {
  display: flex;
  list-style-type: none;
  padding: 0;
}

.page-item {
  margin: 0 5px;
}

.page-link {
  padding: 5px 10px;
  cursor: pointer;
  border: 1px solid #ccc;
  border-radius: 3px;
  text-decoration: none;
  color: #007bff;
}

.page-item.active .page-link {
  background-color: #007bff;
  color: #fff;
  border-color: #007bff;
}

.page-item.disabled .page-link {
  cursor: not-allowed;
  opacity: 0.6;
}

.table_scroll_container {
  overflow-x: auto;
  white-space: nowrap;
}

/* Spinner Styles */
.spinner {
  display: inline-block;
  position: relative;
  width: 24px;
  height: 24px;
  border: 3px solid rgba(0, 123, 255, 0.3);
  border-radius: 50%;
  border-top-color: #007bff;
  animation: spin 1s ease-in-out infinite;
  margin-left: 10px;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

/* Search Bar Styles */
.search-bar {
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
}

.search-input {
  padding: 10px;
  width: 300px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-right: 10px;
}
